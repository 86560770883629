.top-calender-container {
  display: flex;
  overflow-y: hidden;
  div {
    color: rgba(0, 0, 0, 0.6);
  }

  .top-calender-item-container {

    flex-basis: 14%;
    flex-grow: 0;
    flex-shrink: 0;

    .event-item-indicator {
      height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 7px;
        height: 7px;
      }
    }

    .calender-item-selected {
      background: #fafafa;
      border-radius: 21px;
      background: #058ED9;

      div {
        color: white;
      }
    }

    .calender-item-today {
      border: 1px solid #058ED9;
      border-radius: 21px;
    }

    .top-calender-item {

      .calender-item-day-name {
        font-size: 12px;
        letter-spacing: 3px;
        text-align: center;
        height: 14px;
      }

      .calender-item-day-month {
        font-size: 21px;
        font-weight: bold;
        text-align: center;
        height: 26px;
      }

      .calender-item-month-name {
        font-size: 14px;
        text-align: center;
      }

      .calender-item-year {
        font-size: 15px;
        text-align: center;
      }

      .calender-item-date-foreign {
        font-size: 12px;
        text-align: center;
        margin-top: 3px;
      }
    }
  }
}

.event-description-empty {
  height: 20px;
}

.event-description-data {
  white-space: pre-wrap;
  background: #ECD50A;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  text-align: center;
  margin: auto;
  padding: 2px;

  color: #000000;

}