.bottom-links-container{
  display: var(--edit-is-on-disable-none);
  position: fixed;
  width: 100%;
  height: 70px;
  bottom: -1px;
  z-index: 100;

  a {
    width: 100px;
  }
  div{
    width: 100px;
  }

  background: #FFFFFF;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);

  align-items: center;
  justify-content: space-evenly;

  .bottom-link {
    display: flex;
    flex-direction: column;
    align-items: center;

    .side-menu-item-text {
      font-size: 12px;
      color: black;
    }


    .bottom-link-text {
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;

      color: #000000;

    }
  }

  .bottom-link.clicked{
    * {
      color: #058ED9FF;

    }
    svg {
      path {
        fill: rgb(5, 142, 217);
      }
    }
  }
}


.empty-button-div {
  height: 70px;
}