.special-message-container {
  background: rgba(109, 200, 250, 0.29);
  border-radius: 12px;
  margin: 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .special-message-title {

  }

  .special-message-text {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    margin: 5px 20px;

    text-overflow:ellipsis;
    overflow:hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}