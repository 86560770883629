.dvar-tora-edit-container {
  .dvar-tora-edit-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    span {
      font-weight: 400;
      font-size: 14px;
    }
    textarea {
      font-weight: 400;
      font-size: 14px;
    }
    input {
      font-weight: 400;
      font-size: 14px;
    }
  }
}