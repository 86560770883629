.list-details-container-title {
  display: flex;
  align-items: center;

  input {
    width: 40%;
    margin: 0px 20px;
    font-size: 14px;

  }
}

.filter-container {
  display: flex;
  font-size: 20px;

  button {
    width: 50%;
    background-color: white;
    border-width: 4px;
    border-color: #058ED9;
    border-style: solid;
    color: #058ED9;
    span {
      cursor: pointer;

    }
  }
  .button-right {
    border-radius: 0px 10px 10px 0px;
  }
  .button-left {
    border-radius: 10px 0px 0px 10px;
  }
  .clicked {
    background-color: #058ED9;
    color: white;
    span {
      cursor: unset;

    }
  }
  margin: 10px 10px;
}

.filter-by-holiday {
  margin-bottom: 10px;
  width: 100%;
  div {
    width: 100%;
  };
}

.list-details-container-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;

  .select-all-container {
    display: flex;
    .select-all-label {
      margin: 0px 10px;
      font-size: 12px;
      color: #838383;

    }
  }

  * img {
    width: 30px;
    height: 30px;
    filter: invert(86%) sepia(89%) saturate(1%) hue-rotate(270deg) brightness(109%) contrast(97%); //white
  }



  .delete-item-button {
    background: #FF4742;
    border: 1px solid #FF4742;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
  }

    .delete-item-button:hover,
    .delete-item-button:disabled,
    .delete-item-button:active {
      background-color: initial;
      background-position: 0 0;
      color: #FF4742;
    }

    .delete-item-button:active {
      opacity: .5;
    }

}

.add-item-button {
  left: 14px;
  top: 78px;
  position: fixed;
  z-index: 100;
}

.list-item {
  display: flex;
  align-items: center;

  .checkbox {
    margin: 20px;
  }

  .list-item-divider {
    width: 8px;
  }

  .clone-item-button {
    background: #058ED9;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    height: 44px;
    line-height: 44px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    text-align: center;
    font-size: 14px;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    width: fit-content;
    display: flex;
    justify-content: space-around;
  }

  .preview-item{
    overflow: hidden;
    display: flex;
    //align-items: center;
    width: 100%;
    background-color: #c2eaff;
    border-radius: 13px;
    padding: 5px;
    flex-wrap: wrap;
    margin-top: 10px;

    .player-container {
      object-fit: cover;
      border-radius: 10%;
      height: 100px !important;
      width: 100px !important;
    }

    .key_value {
      width: 45%;
      .key {
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.4);
      }
      .value {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
      .attachment-value {
        width: 40px;
        object-fit: cover;
        border-radius: 10%;
      }
    }

    .cloud-item {
      width: 10%;
      margin: auto;
      text-align: center;
      img {
        width: 20px;
        height: 20px;
      }
    }


  }
}

.list-details-actions {
  margin-bottom: 5px;
  * {
    font-weight: 500;
    font-size: 18px;
  }
}

.dont-show {
  display: none;
}