.payment-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .mysynagogue-logo {
    width: 100px;
    margin-bottom: 20px;
  }

  .payment-modal-title {
    font-weight: 500;
    font-size: 16px;
  }

  .communications-actions {
    display: flex;
    justify-content: center;
    align-items: baseline;

    .payment-button {
      margin: 20px 20px 50px;

      img {
        width: 35px;
        height: 35px;
      }
    }
  }
}

.iframe_payment {
  width: 100%;
  height: 50vh;
  margin-bottom: 10px;
}

.link_to_external_payment_url {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #c2eaff;
  padding: 6px;
  border-radius: 7px;

  img {
    width: 35px;
    height: 35px;
  }

  margin-bottom: 10px;
}