.offline-container {
    background-color: rgba(228, 255, 24, 0.28);
    height: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .offline-text {
        font-size: 14px;
    }
}

/*desktop*/
@media (min-width: 769px) {
    .offline-container {
        display: none;
    }
}