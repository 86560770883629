.daily-events-container {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #000000;

  background: rgba(109, 200, 250, 0.29);
  border-radius: 12px;
  margin: 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;


  .daily-events-title {
    font-weight: 500;
    font-size: 14px;
    margin: 10px;
  }



  .single-study-event-container {
    background-color: rgba(109, 200, 250, 0.29);

    margin: 10px;
    width: 100%;

    .increase-decrease-text {
      display: flex;
      justify-content: space-around;

      button {
        background: rgba(109, 200, 250, 0.29);
        border-radius: 12px;
      }
    }
  }

  .title {

    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;

    .title-icon {
      img {
        width: 30px;
        height: 30px;
      }

    }

    .title-text {
      margin-right: 20px;
    }
  }


}