.dvar-tora-container {
  background: rgba(109, 200, 250, 0.29);
  border-radius: 12px;
  margin: 10px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .dvar-tora-title {

  }

  .dvar-tora-video {
    iframe {
      width: calc(100vw - 79px);
      min-height: 211px;
    }
  }

  .dvar-tora-text {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    margin: 5px 20px;

    text-overflow:ellipsis;
    overflow:hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7; //max 7 lines
    -webkit-box-orient: vertical;
  }
}

.dvar-tora-text.show-full {
  overflow: unset;
  display: unset;


}