.filters-container {
  display: flex;
  margin: 0px 8px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;


  .filter-selected {

    border-color: #57aedd !important;
    color: #57aedd !important;
  }

  .filter-container-times {
    border-radius: 6px;
    border-color: #A39A9280;
    color: #A39A9280;
    border-style: solid;
    border-width: thin;

    margin: 0px 10px;
    margin: 0px 5px;

    span {
      margin: 0px 2px;

    }

    .filter-icon > svg > path {
      fill: #A39A9280;
    }
  }

  .filter-selected {
    .filter-icon > svg > path {
      fill: #57aedd;
    }

  }



  .move-to-today {
    flex-grow: 2;
    display: flex;
    flex-direction: row-reverse;

    text-decoration-line: underline;
    color: #058ED9;
  }
}

