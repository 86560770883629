.set-language-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 8px;

  position: fixed;
  top: 47px;
  right: 77px;

  background: #FFFFFF;
  border-radius: 12px;

  .select-language-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 72px;
    justify-content: space-evenly;

    svg {
      font-size: 24px;
    }



    span {
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
    }
  }
}