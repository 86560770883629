.smart-notification-container {
  width: 48%;

  background-color: #C2EAFF;
  border-radius: 12px;
  padding: 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .display-full-date {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    text-align: center;

    color: #000000;
  }

  .display-latest-pray-container {
    display: flex;
    flex-direction: column;
    align-items: center;


    .display-latest-pray-time {
      font-family: 'Bitter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
    }
    .display-latest-pray-text {
      font-family: 'Bitter';
      font-style: normal;
      font-size: 14px;
      line-height: 19px;
    }

  }
}