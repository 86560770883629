.synagogue-details-edit-container {
  .synagogue-details-edit-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    span, textarea, input, select {
      font-weight: 400;
      font-size: 14px;
    }
  }
  .key-value-details {
    * {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .bottom-buttons {
    display: flex;
    flex-direction: column;

    button {
      background: #058ED9;
      border-radius: 5px;
      color: #FFFFFF;
      height: 44px;
      line-height: 44px;
      outline: 0;
      overflow: hidden;
      padding: 0 20px;
      pointer-events: auto;
      text-align: center;
      font-size: 14px;
      touch-action: manipulation;
      user-select: none;
      -webkit-user-select: none;
      vertical-align: top;
      white-space: nowrap;
      width: 100%;
      z-index: 9;
      border: 0;
      display: flex;
      justify-content: space-around;
    }

    .bottom-buttons-save {
      margin-bottom: 10px;
    }

    .bottom-buttons-back {
      background: white;
      color: #058ED9;
      border-color: #058ED9;
      border-style: solid;
      border-width: 1px;
    }

  }

}