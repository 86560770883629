.bottom-buttons-container {
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-right: 10px;
  margin-left: 10px;


  .bottom-button {
    width: 45%;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    background: #058ED9;
    border-radius: 12px;
    color: white;
    align-items: center;

    .bottom-button-text {
      font-family: 'Heebo';
      font-style: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: center;

      display: flex;
      flex-direction: column;

      .bold-text {
        font-weight: 900;

      }

    }

  }
}
