.main-v2-container {
  background-color: white;

}

.top-main-container {
  background-color: white;

  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}