.item-details-container {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
  }

  .body {
    .filed-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      span {
        font-weight: 400;
        font-size: 14px;
      }

      input, select, textarea{
        font-weight: 400;
        font-size: 16px;
      }

      .object-to-render {
        display: flex;
        padding: 10px 40px;
        flex-direction: column;
        .delete-object-item {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .add-object-item {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .warn-msg {
        color: #2b9f54;
        font-size: 12px;

      }
    }

    .dynamic-content {
      background-color: #d5eefc;
      padding: unset;
      margin: unset;
      padding: 3px;

    }
  }

  .donation-dept-container {

  }

  .offset-dept {
    input {
      width: 100px;
    }
  }
}

.bottom-buttons {
  display: flex;
  flex-direction: column;

  button {
    background: #058ED9;
    border-radius: 5px;
    color: #FFFFFF;
    height: 44px;
    line-height: 44px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    text-align: center;
    font-size: 14px;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
    border: 0;
    display: flex;
    justify-content: space-around;
  }

  button:disabled {
    background-color: #b5b5b5;
  }

  .bottom-buttons-save {
    margin-bottom: 10px;
  }

  .bottom-buttons-back {
    background: white;
    color: #058ED9;
    border-color: #058ED9;
    border-style: solid;
    border-width: 1px;
  }

}

.avatar-item {
  margin: 0px 5px;

  img {
    width: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.pdf-item {
  cursor: pointer;
  background-color: #c2eaff;
  margin: 5px;
  border-radius: 13px;
  img {
    width: 40px;
    object-fit: cover;
  }
}

.save-msg-warning-container {
  font-size: 10px;
  color: red;
}

.regular-button {
  color: #FFFFFF;
  border-color: #058ED9;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  background-color: #058ED9;
  border-radius: 10px;
}

.regular-button:disabled {
  background-color: #b5b5b5;
}

.disabled {
  border-color: #058ED9;

}

.delete-item-button {
  background: #FF4742;
  border: 1px solid #FF4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}