.notifications-container {
  padding: 15px;
  .notifications-title {
    font-family: 'Bitter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #000000;
  }

  .notifications-array {
    .notification-item.not-read * {
      background-color: #e5edf6;

    }
    .notification-item {
      .notification-item-title {
        span {
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 19px;

          color: #000000;
        }
      }
      .notification-item-body {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;

        color: #000000;

        .attachment-item {
          img {
            width: 100%;
            height: 100%;
          }
          iframe {
            width: 100%;
            height: 100%;
          }
        }

        .link-item {
          color: #1890ff;
        }
      }
      .notification-item-date {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        color: rgba(0, 0, 0, 0.5);
      }

    }
  }

}

.notification-item-end-line {
  border: 1px solid #C2EAFF;
}