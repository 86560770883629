.next-shabbat-container {
  width: 48%;
  background-color: #C2EAFF;
  border-radius: 12px;
  padding: 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .parash-name-container {
    font-family: 'Heebo' !important;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    justify-content: center;

    div {
      margin: 0px 1px;
    }

    .select-next-parasha {
      .ant-select-selector {
        background-color: #abdafd;
        border-radius: 5px;

        padding: 0 3px 0px 32px;
      }
    }
    .ant-select-selection-item {
    }

    .ant-select-arrow {
      right: unset;
      left: 11px;
      top: 40%;
    }

  }

  .enter-exit-shabbat-container {
    text-align: center;

    display: flex;
    justify-content: space-evenly;

    .enter-exit-shabbat-item {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .enter-exit-shabbat-time {
        font-size: 16px;
      }

      .enter-exit-shabbat-name {
        font-size: 10px;
      }
  }


  }


  .bold-text {
    font-weight: bold;
  }
}