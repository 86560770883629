.upload-container {
  display: flex;
  flex-direction: column;

  .ant-upload.ant-upload-select-picture-card {
    width: 100px;
    height: 100px;
  }
}

.uploader-synagogue-logo {
  text-align: center;
  div{
    margin: unset!important;
  }
}

.delete-logo-btn {
  margin-top: 10px;
}