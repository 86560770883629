.beta-version {
  background-color: yellow;
  font-size: 16px;
  text-align: center;
}

@media screen and (min-width: 800px) {
  // this css will only be used when the screen size is min 800px
  .management-container {
    margin: 0px 30%;
  }
  .add-item-button {
    margin: 0px 25%;
  }
}