.signup-container {

  margin: 10px;

  .icon_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: -20px;
  }

  .welcome-title {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    font-weight: 400;

  }

  .signup-fields-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }


  .signup-field-container {
    font-size: 2vh;
    margin: 10px;
    width: 75%;
    max-width: 350px;

    input {
      width: 100%;
    }
  }


  .logical_name_container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .create_app {
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-size: 2vh;
    width: 75%;
    max-width: 350px;


    margin-bottom: 50px;

    button {
      display: flex;
      font-size: 16px;
      background: #058ED9;
      border-radius: 12px;
      width: 100%;
      height: 55px;
      font-family: 'Heebo';
      font-style: normal;
      font-weight: 500;
      text-align: center;
      color: #FFFFFF;
      align-items: center;
      justify-content: center;
    }

  }

  .validation_error {
    font-size: 2vh;
    color: red;

    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .new-synagogue-created {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: bold;

    .transferred_to {
      font-weight: 300;
      font-size: 2vh;
      color: #00a7ff;

    }

    .icon_container {
      margin: 30px;
    }
  }
}

/*this css will only be used when the screen size is min 800px**/
@media screen and (min-width: 800px) {
  .signup-container {
    text-align: center;
    width: 50%;
    margin: auto;
  }
}