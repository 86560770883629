.select-giff-container {
  display: flex;
  flex-wrap: wrap;
  .select-giff {
    display: flex;
    flex-direction: column;
    margin: 10px;

    background-color: var(--edit-is-on-background-color);;
    padding: 5px;
    border-radius: 7px;


    img {
      width: 50px;
      height: 50px;
      margin-bottom: 5px;
    }
  }


}

.add-youtube-link {
  display: flex;
  flex-direction: column;

  .player-container {
    margin-top: 10px;
    height: 300px !important;
    width: 100% !important;
  }
}

