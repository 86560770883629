.side-menu-container {

  height: 100%;
  background: #fff;
  box-shadow: 1px 0px 7px rgb(0,0,0.5);
  position: fixed;
  top: 59px;
  right: 0;
  width: 53%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  padding-right: 42px;
}

.side-menu-container.open{
  transform: translateX(0);
}

.side-menu-container.management-on{
  background-color: #058ED9;
  * {
    color: white;
  }

}

.top-side-menu-container {

  height: 72%;
  display: flex;
  flex-direction: column;
}

.top-side-menu-item {
  direction: rtl !important;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  color: #0D2431
}

.bottom-side-menu-container {
  z-index: 200;

}

.side-menu-gabbai-icon-container {
  background-color: #058ED9;
  border-radius: 84px;
  width: 23px;
  text-align: center;
}

.side-menu-gabbai-divider {
  width: 10px;
}

.side-menu-app-version {
  font-size: 10px;
  text-align: left;
  margin-top: 10px;
  margin-left: 4px;
}