.first-on-boarding {
  width: 90%;
  position: fixed;
  transition: width .5s ease;
  color: black;
  text-align: center;
  z-index: 100;
  background: #ECD50A;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 20%;
  left: 5%;
  height: 40%;
  outline: 5px solid #dadada;
  display: flex;
  flex-direction: column;

  .click-here {
    background: rgb(236, 213, 10);
    opacity: 1;
    border-radius: 12px;
  }
}