.plasma-edit-container {
  .plasma-edit-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    span {
      font-weight: 400;
      font-size: 14px;
    }
    textarea {
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.advanced-plasma-setting-item-container {
  background: #C2EAFF;
  border-radius: 12px;
  margin-top: 15px;
  padding: 10px;

  * {
    font-weight: 400;
    font-size: 14px;
  }

  .slide_name {
    span {
      font-weight: 500;
      font-size: 16px;
    }

  }
}

.plasma-choose-background-image {
  margin: 10px 0px;

  .background_images {
    display: flex;
    flex-wrap: wrap;
  }


  .background_image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 6px;
    background: #C2EAFF;
    border-radius: 8px;

    img {
      width: 50px;
      height: 50px;
      margin-bottom: 10px;

    }
  }
}