.personal-hebrew-dates-manager-item {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #c2eaff;
  border-radius: 13px;
  padding: 5px;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 10px;

  .hebrew-dates-manager-item-actions-bar {
    display: flex;
    width: 20%;
    justify-content: space-between;
    .hebrew-dates-manager-item-action-bar {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }
      span {
        font-weight: 200;
        font-size: 12px;
      }
    }
  }

  .hebrew-dates-manager-item-key-values {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    .item-label-value {
      margin: 5px;
      .item-label {
        font-weight: 300;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.4);
      }
      .item-value {
        font-weight: 500;
        font-size: 15px;
        color: #000000;
      }
    }
  }

}