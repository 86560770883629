.plasma-header-container {

  margin-right: 30px;
  margin-left: 30px;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-time {
    display: flex;
    align-items: baseline;
    .header-time-clock {
      font-size: 8vh;
      font-weight: 700;
    }
    .header-time-hebrew-date {
      font-weight: 700;
      font-size: 5vh;
      margin: 0px 30px;
    }
    .header-time-date {
      font-size: 3vh;
    }
  }

  .header-icon {
    height: 100%;
    margin-top: 10px;
    * {
    width: 100%;
    height: 83px;
    }
  }
}