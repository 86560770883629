.welcome-container {
  padding: 20px;
  .welcome-title {
    span {
      font-family: 'Heebo';
      font-weight: 900;
      font-size: 24px;
    }
  }

  .welcome-sub-title {
    span {
      font-family: 'Heebo';
      font-weight: 400;
      font-size: 18px;
    }
  }

  .welcome-text {
    span {
      font-family: 'Heebo';
      font-weight: 700;
      font-size: 18px;
    }
  }

  .select-synagogue {
    width: 100% !important;

    font-family: 'Heebo';
    font-weight: 400;
    font-size: 18px;


    .ant-select-selector {
      border: 1px solid #058ED9 !important;
      border-radius: 12px;
    }

    .ant-select-arrow {
      color: #058ED9;
    }

  }

  .leave-details-button {
    display: flex;
    font-size: 16px;
    background: #058ED9;
    border-radius: 12px;
    width: 100%;
    height: 55px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;

  }

  .welcome-why-mysynagogue {
    display: flex;
    justify-content: space-around;
    div {
      width: 23%;
      height: 120px;

      background: #CDE8F7;
      border-radius: 12px;

      display: flex;

      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      img {
        margin-top: 15px;
      }

      span {
        margin-top: 20px;

        font-family: 'Heebo';
        font-weight: 400;
        font-size: 12px;
        text-align: center;

      }

    }
  }

  .recommendation-container {
    width: 100%;
    height: 240px;
    background: #ECD50A;
    border-radius: 12px;
    margin-top: 20px;

    .recommendation-title {
      text-align: center;
      padding-top: 15px;


      span {

        font-family: 'Heebo';
        font-weight: 500;
        font-size: 16px;
      }

    }

    .recommendation-text-container {
      background-color: #fafafa;
      text-align: center;
      height: 176px;
      margin: 4px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-bottom: 16px;


      .recommendation-text-description {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 400;
        color: #000000;
        font-size: 14px;


      }

      .recommendation-text-who {
        font-family: 'Heebo';
        font-weight: 300;
        font-size: 12px;
        color: #000000;
      }

      .recommendation-text-date {
        font-family: 'Heebo';
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        color: #000000;
      }

    }

    .recommendation-scroller {

      * {
        direction: rtl !important;
      }
    }


  }

  .our-partners {
    border: 1px solid #058ED9;
    margin-top: 20px;


    .our-partners-title {
      height: 55px;
      background-color: #058ED9;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-family: 'Heebo';
        font-weight: 700;
        font-size: 24px;
        color: #FFFFFF
      }
    }

    .our-partners-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      img {
        height: 100px;
      }
    }



  }

}


/*this css will only be used when the screen size is min 800px**/
@media screen and (min-width: 800px) {
  .welcome-container {
    text-align: center;
    width: 50%;
    margin: auto;
  }
}