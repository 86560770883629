.personal-hebrew-dates-manager {

  .personal-hebrew-dates-manager-title {
    margin: 10px;

  }
  .add-hebrew-date-button {
    left: 14px;
    top: 115px;
    position: fixed;
    z-index: 100;
  }

  .personal-hebrew-dates-manager-items {
    margin: 10px;
  }
}