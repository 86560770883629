////////////memorial-mode:

.hebrew-board-events-container.memorial-mode {
  background: rgb(0,0,1);
  background: linear-gradient(270deg, rgba(0,0,1,1) 0%, rgba(19, 17, 17, 0.31) 100%, rgba(143, 154, 154, 0.02) 100%);
  color: white;

  .hebrew-board-events-items {
    .hebrew-board-event-container{
      background-color: black;
      border: 1px solid #ffffff;

      font-weight: 200;

      .hebrew-board-event-memory-candle {
        img {
          width: 5vh;
          height: 5vh;
        }
      }


    }
  }
}


////////////

.hebrew-board-events-container {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  border-radius: 12px;
  padding: -2px;

  color: #058ED9;
  font-weight: 900;



  .hebrew-board-events-items {
    font-size: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-items: center;

    height: 100%;
    width: 100%;


    .hebrew-board-event-container {
      background-color: rgba(255, 255, 255, 0.5);

      border: 1px solid #058ED9;
      margin: 2vw;
      border-radius: 12px;
      padding: 1vw;
      min-width: 250px;
      .hebrew-board-event-memory-candle {
        img {
          width: 10vh;
          height: 10vh;
        }
      }
    }
  }

  .hebrew-board-events-wide-image {
    text-align: center;
    img {
      width: 40vh;
      height: 20vh;
    }
  }
}

