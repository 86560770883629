.float-button-donation-container {
  position:fixed;
  width: 112px;
  transition: width .5s ease;
  height: 38px;
  bottom:84px;
  left:14px;
  color:black;
  text-align:center;
  z-index: 100;
  background: #ECD50A;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  .empty-donate-area {
    width: 5px;
  }

  span {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;

  }
}

.float-button-donation-container.minimize {
  width: 40px;
  transition: width .5s ease;
}