.donation-container {
  margin: 10px 20px;

  .donation-title {
    font-family: 'Bitter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
  .donation-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .donation-tab {
      background: rgba(5, 142, 217, 0.2);
      border-radius: 8px 8px 0px 0px;
      width: 25%;
      height: 44px;
      margin: 0px 2px;

      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: space-around;
      padding: 10px;


    }

    .donation-tab.selected {
     background-color: #058ED9;
    }
  }
  .donation-body {
    min-height: 252px;
    border: 1px solid #058ED9;
    border-radius: 0px 12px 12px 12px;
    padding: 10px;
  }

  .donation-body-extra-details {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;

    color: #000000;
  }

  .donation-body-iframe {
    iframe {
      height: 100vh;
      width: 100%;
      overflow: hidden;
    }
  }

  .donation-body-paybox {
    word-break:break-all;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
}