.gematria-calculator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 16px;

  .gematria-calculator-icon {
    svg {
      width: 50px;
      height: 50px;
    }
  }

  .gematria-calculator-body {
    width: 100%;

    .gematria-calculator-input {
      width: 100%;
    }

    .gematria-calculator-value {
      text-align: center;
      font-weight: 700;
    }
  }
}

.bottom-button {
  margin-top: 30px;
  width: 45%;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  background: #058ED9;
  border-radius: 12px;
  color: white;
  align-items: center;

  .bottom-button-text {
    font-family: 'Heebo';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    display: flex;
    flex-direction: column;

    .bold-text {
      font-weight: 900;

    }

  }

}