.history-payments-container {
  overflow: scroll;
  max-height: 300px;
  .history-payment{
    display: flex;
    align-items: center;

    overflow: hidden;
    display: flex;
    width: 100%;
    background-color: #c2eaff;
    border-radius: 13px;
    padding: 5px;
    flex-wrap: wrap;
    margin-top: 10px;

    .key_value {
      width: 45%;

      .key{
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.4);
      }
      .value {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }
    }

  }
}