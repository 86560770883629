@import '~antd/dist/antd.css';

html {
    height: 100vh;

}

:root {
    --app-direction: rtl;
    --app-text-align: right;
    --edit-is-on-disable-none: flex;
    --edit-is-on-background-color: #fff;
}

* {
    /*font-family: 'Varela Round', sans-serif !important;*/
    font-family: 'Heebo', sans-serif;
    direction: var(--app-direction) !important;
}

@font-face {
    font-family: 'Varela Round', sans-serif !important;
    src: url('./fonts/VarelaRound-Regular.ttf'); /* IE9 Compat Modes */
    src: local('Open Sans'), local('OpenSans'),
    url('./fonts/VarelaRound-Regular.ttf') format('embedded-opentype'), /* IE6-IE8 */
    url('./fonts/VarelaRound-Regular.ttf') format('woff2'), /* Super Modern Browsers */
    url('./fonts/VarelaRound-Regular.ttf') format('woff'), /* Modern Browsers */
    url('./fonts/VarelaRound-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./fonts/VarelaRound-Regular.ttf') format('svg'); /* Legacy iOS */
}

body {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-size: 1.4rem;
}

main {
    padding-top: 64px !important;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-right: 5px;
    margin-left: 5px;
    overflow: auto;
    overflow-wrap: break-word;
    height: 100%;
}

.App {
    background-color: #e6e5e1;;
}

.ant-descriptions-item-label,.ant-timeline-item-head,.ant-pagination-item{
    background-color: unset !important;
}

.ant-collapse-header,.ant-collapse-header,.ant-collapse-header,.ant-descriptions-item-label,.ant-alert-message,.ant-descriptions-title,.hebrew-date,h2,h3,h5{
color: rgba(0, 0, 0, 0.65) !important;
}

.ant-table-tbody,.ant-card-head,.ant-card-body,.ant-collapse,.ant-collapse-content,.ant-card{
    background-color: #e6e5e1;
}