.info-container {
  padding: 6px 14px;

  .info-top-container {
    display: flex;

    .info-top-pic{
     width: 50%;
    }

    .info-top-links {
      width: 50%;

      .main-link-container {

        a{
          color: unset;
          text-decoration: none;
        }

        .main-link-text {
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          inline-size: fit-content;

          color: #000000;
        }
      }

      .mini-icons-container {
        .mini-icon {
          margin: 0px 3px;
        }
      }

    }
  }

  .info-body{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;


    color: #000000;
  }
}