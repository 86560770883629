.no-invoices-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 30px;

  background: #C2EAFF;
  border-radius: 12px;


  font-size: 25px;
  font-weight: 500;

  .first {
    font-size: 15px;
  }

  img {
    width: 100px;
  }
}

.sign-digital {
  border-style: solid;
  border-width: 1px;
}

.clean-preview-signature {
  display: flex;
  align-items: center;
  justify-content: space-around;

  img {
    width: 100px;
  }

}