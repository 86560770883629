.make-push-notification {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .title {
    text-align: center;
    font-weight: 500;
    font-size: 22px;
  }

  input, textarea {
    font-size: 18px;
  }

  .success {
    font-weight: 500;
    font-size: 16px;

  }
  * {
    margin-top: 5px;
  }
}