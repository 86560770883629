.parasha-viewer {
  color: #00a7ff;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
}

.aliya-divider {
  height:5px;
  border-width:0;
  background-color:var(--edit-is-on-background-color);
}