.plasma-container {
  height: calc(100vh - 72px);
}

.plasma-slider-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 128px);
}

  .plasma-slide-body {
    word-wrap: break-word;
    white-space: pre-wrap;
    border: 1px solid #058ED9;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    margin-left: 30px;
    padding: 2px;
    flex-grow: 1;


    .time-of-the-day-slide {
      background-color: rgba(255, 255, 255, 0.5);

      font-size: 5vh;
    }

    .scan-barcode-slide {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 25vw;
        height: 25vw;
      }
      a {
        font-size: 2vw;
      }
    }

    .plasma-slide-attachment {
      display: flex;
      justify-content: center;
      padding: 10px;
      max-width: 50vh;
      max-height: 50vh;

      height: 50vh;


      img{
        //max-width: 100%;
        max-height: 100%;
      }
    }




    .mashiv-haRuach-slide {
      background-color: rgba(255, 255, 255, 0.5);

      * {
        text-align: center;
      }

      .main {
        font-weight: 500;
        font-size: 12vh;
      }

      .sub-main {
        font-size: 7vh;
      }
    }
}

.plasma-slide-full-size-picture {
  height: 78vh;

  .plasma-slide-attachment {
    display: flex;
    justify-content: center;
    padding: 10px;
    width: 100%;
    height: 100%;

    img{
      max-width: 100%;
      max-height: 100%;
    }
  }

  .plasma-slide-youtube {
    width: 100%;
    height: 100%;
    .player-container-plasma-slide {
      height: 100% !important;
      width: 100% !important;
    }
  }

}





.plasma-bottom-donation {
  position: fixed;
  width: 100%;
  height: 70px;
  bottom: 0px;
  z-index: 200;
  background: #D5EFFD;
  display: flex;
  align-items: center;
  justify-content: center;
}


.important-dates-slide {
  background-color: rgba(255, 255, 255, 0.5);

}