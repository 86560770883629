.plasma-main-title-container {
  display: flex;
  justify-content: center;
  font-size: 3vw;
  font-weight: 700;
  background: linear-gradient(193.88deg, rgba(109, 200, 250, 0.5) 4.67%, rgba(5, 142, 217, 0.5) 90.09%);
  border-radius: 12px;
  margin: 0px 30px 20px 30px;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: center;


  div,span {
    width: 100%;
    height: 100%;
    white-space: pre-wrap;
    word-wrap: break-word
  }
}