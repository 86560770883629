.reports-v2-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  div {
    font-size: 17px;
    width: 100%;

  }
}

.top-menu-bar-reports {
  align-items: unset;
  font-size: 17px;

}

.pdf-mode-container {

  margin-top: 50px;

  display: flex;
  flex-direction: column;
  width: 100%;

  .statics-select {
    min-width: 200px;
    margin-bottom: 20px;
  }
}

.report-body-container {
  display: flex;
  flex-direction: column;
  font-size: 17px;
  width: 100%;
}

.report-body-container > div,button{
  margin-top: 20px;
}

.report-body-container > button{
  margin-top: 20px;
}



.main-button-report {
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #39739d;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.main-button-report:hover,
.main-button-report:focus {
  background-color: #b3d3ea;
  color: #2c5777;
}

.main-button-report:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.main-button-report:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: #2c5777;
}

.divider-report {
  height: 3px;
  border: none;
  border-radius: 6px;
  background: #C2EAFF;
  width: 100%;

}

.choose-export-type {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;

}

.list-details-actions-export-pdf {
  width: fit-content;
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #39739d;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI","Liberation Sans",sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0 10px;
  padding: 8px .8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.list-details-actions-export-pdf:hover,
.list-details-actions-export-pdf:focus {
  background-color: #b3d3ea;
  color: #2c5777;
}

.list-details-actions-export-pdf:focus {
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
}

.list-details-actions-export-pdf:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: #2c5777;
}

.empty-divider {
  width: 10px;
}

.from-date-container {
  margin-top: 10px;
  margin-bottom: 10px;
}