
.edit-mode{
    padding: 10px;
    direction: rtl;
    .ant-collapse-item-active {
        background: #fbf5e9;
    }

    .main-edit-title > div > span{
        font-size: 17px;
        text-align: center;
    }
}


.edit-mode button{
    direction: ltr;
}

.edit-name-container{
    display: flex;
    flex-direction: row;
}

.edit-updates-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        margin-bottom: 20px;
    }
}

.edit-info-container{
    display: flex;
    flex-direction: row;
}

.info-container{
    text-align: var(--app-text-align);
}

.collapse-edit > div {
    font-weight: bold;
    font-size: 1.4rem;
}

.panel-header-torah-manger-icon-new {
    font-size: 1.4rem;
}

