.top-level-v2-container {
  background-color: var(--edit-is-on-background-color);;
  padding: 0px 16px;
  .top-level-empty-div {
    height: 22px;
  }

  .top-level-main-bar {
    height: 36px;

    .top-level-main-bar-buttons {
      direction: rtl !important;

      display: flex;
      justify-content: space-between;

      .top-level-main-bar-buttons-right {
        direction: rtl !important;

        width: 110px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 200;

        .top-level-main-bar-icon {
          svg {
            margin: 5px;
          }
        }

        .notification-circle {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          position: absolute;
          top: 15px;
          background: #ECD50A;
          display: flex;
          justify-content: center;
          align-items: center;
          color: black;
          font-size: 14px;
          right: 47px;
        }
      }

    }
  }

  .top-level-synagogue {
    display: var(--edit-is-on-disable-none);

    height: 36px;
    align-items: center;


    .top-level-synagogue-logo-container {
      height: 31px;
      width: 31px;

      img{
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }

    .top-level-synagogue-name-diveder {
      width: 15px;
    }

    .top-level-synagogue-name {
      font-family: 'Heebo' !important;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 26px;
      color: black;
    }
  }

}

.top-level-main-bar-buttons-right.plasma-mode-on{
  position: fixed;
  z-index: 200;

  svg {
    width: 50px;
    display: flex;

  }

}

.top-level-main-bar-buttons-left {
  display: flex;
  align-items: center;
  .top-level-main-bar-icon.israel-flag{
    height: 25px;
  }
}


