.general-events-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;


  .general-events-item {
    background-color: #d5effd82;
    margin: 10px;
    border-radius: 12px;
    padding: 20px;

  }
}