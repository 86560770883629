.plasma-detailed-screen {
  display: flex;
  height: 92vh;
  justify-content: space-around;

  div {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .plasma-detailed-screen-middle {
    margin: 15px 0px;


    .plasma-detailed-screen-middle-logo {
      width: 20%;
    }

    .plasma-detailed-screen-middle-name {
      font-weight: 900;
      font-size: 3vh;
      text-align: center;
    }

    .plasma-detailed-screen-middle-clock {
      margin-top: 1vh;

      background-color: rgba(130, 199, 236, 0.5);


      height: 20%;
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;


      font-family: 'Heebo';
      font-style: normal;
      font-weight: 700;
      font-size: 10vh;
      //align-items: baseline;

      .clock-seconds {
        font-size: 3vh;
        margin-top: 42px;


      }
    }

    .plasma-detailed-screen-middle-next-shabat {
      margin-top: 1.5vh;
      margin-bottom: 1.5vh;
      border: 1px solid #058ED9;
      border-radius: 12px;

      .next-shabbat-container {
        background-color: rgba(255, 255, 255, 0.5);


        * {
          font-size: 2.5vh;
        }

        .enter-exit-shabbat-container {
          display: flex;
          flex-direction: row;
        }

        .parash-name-container {
          .bold-text {
            margin-top: 10px;;
          }
        }

      }
    }

    .plasma-detailed-screen-middle-updates{
      margin-top: 3vh;
      border: 1px solid #058ED9;
      border-radius: 12px;
      font-size: 2.5vh;
      background-color: white;
      padding: 1vw;
      font-weight: 900;
    }

    .plasma-detailed-screen-middle-full-hebrew-date {
      font-weight: 700;
      font-size: 4vh;
    }

    .plasma-detailed-screen-middle-date-events {
      margin-top: 2vh;
      background-color: #C2EAFF;

      background-color: rgba(194, 234, 255, 0.5);


      white-space: pre-wrap;
      font-weight: 500;
      font-size: 2vh;
    }
  }

  .plasma-detailed-screen-event-times {
    border: 1px solid #058ED9;
    border-radius: 12px;
    margin: 30px 2%;
    * {
      color: black;
    }

    background-color: rgba(255, 255, 255, 0.5);


    .plasma-detailed-screen-event-day-name{
      display: flex;
      align-content: flex-end;
      flex-direction: row;
      font-size: 2.5vh;
      justify-content: center;
      background-color: #82C7EC;
      border-radius: 12px 12px 0px 0px;

      span {
        margin: 10px 2px;
      }

      span:first-child {
        font-weight: 700;
      }
    }


    .plasma-detailed-screen-title {
      font-size: 3vh;
    }


    .display-events-by-Date {
      max-height: unset;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      //overflow: hidden;

      *{
        font-size: 4.5vh;
        white-space: nowrap;

      }

      .display-event-container {
        display: flex;
        flex-direction: column;
        min-height: unset;
        margin: 1vh 0px;


        .display-event-more-less {
          display: none;
        }

        .display-event-empty-middle {
          display: none;

        }

        .display-event-actions {
          display: none;
        }

        .display-event-empty {
          display: none;
        }

        .display-event-body {
          width: unset;
          margin-top: unset;
          text-align: center;
          .display-event-description {
            display: none;

          }
        }

        //.display-event-time {
        //  width: unset;
        //  margin: 0px 2vw;
        //  font-weight: 800;
        //  font-size: 4vh;
        //  color: #000000;
        //}

        .display-event-name-and-icon {
          display: flex;
          flex-direction: row;
          justify-content: center;
          .display-event-name {
            margin: 0px 10px;
            flex-basis: 0;

          }
        }
      }
    }
  }

}