.unisyn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
  padding: 10px 20px;

  .unisyn-main-logo {
    margin-bottom: 30px;
  }

}

.button-links-container {
  width: 100%;
  display: flex;
  margin-top: 20px;
  font-size: 10px;
  justify-content: space-around;

  .button-link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0px;
  }

  .button-links-circle {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .button-link-text {
    color: #484848;
  }
}
.dynamic-data-ontainer {
  width: 100%;
  .dynamic-data-title {
    font-size: 23px;
  }
  .dynamic-data-sub-title {
    font-size: 20px;
    margin-top: 25px;
  }
  .dynamic-data-body {
    font-size: 15px;
    margin-top: 45px;
  }
}

.unisny-icon {
  svg {
    width: 100px;
    height: 100px;
  }
}