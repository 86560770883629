.scores-modal {
  .scores-modal-title {
    text-align: center;
  }

  .scores-modal-content {

    height: 50vh;
    overflow-y: scroll;
    text-align: center;


    .score-item {
      display: flex;

      .score-number {
        font-weight: 900;
        font-size: 22px;
        display: flex;
        align-items: center;

        margin: auto;
        width: 30px;
      }

      .score-item-data {
        overflow: hidden;
        display: flex;
        width: 100%;
        background-color: #c2eaff;
        border-radius: 13px;
        padding: 5px;
        flex-wrap: wrap;
        margin-top: 10px;

        .break{
          width: 5px;
        }

        .key_value {
          width: 25%;

          .key {
            font-weight: 400;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.4);
          }
          .value {
            font-weight: 500;
            font-size: 14px;
            color: #000000;
          }
        }
      }

      .score-item-data.yellow {
        background-color: yellow;
      }

    }

  }
}
