
.auth-container{
    display: flex;
    flex-direction: column;
}
index
.auth-container button{
   direction: ltr;
}

.auto-save-password {
    margin-top: 5px;
    font-size: 15px;
}