.union-of-synagogues-link-container {
  border: 0.5px solid #058ED9;
  border-radius: 12px;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin: 10px 20px;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;

}