.welcome-title{
  text-align: center;
}

.word-search-container {
  margin: auto;

  .word-search-row {
    display: flex;
    width: auto;
    justify-content: center;
    flex-direction: row-reverse;
  }

  .word-search-column {
    width: 40px;
    height: 40px;
    border: 1px #666 solid;
    background-color: #efefef;
    text-align: center;
    margin-left: 0px;
    margin-top: 0px;
    line-height: 40px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    box-sizing: border-box;
    transition: all 0.1s;
    user-select: none;
    color: #555;
  }

  .word-search-column.clicked {
    background-color: #00a7ff;
  }

  .word-search-column.matched {
    background-color: #11ff00;
    font-weight: bold;

  }
}

.top-level-container {
  display: flex;
  margin: 2px 10px;
  min-height: 100px;


  .un-matched-words-container {
    display: flex;
    flex-wrap: wrap;
    margin: 4px;
    min-height: 50px;
    align-items: center;


    .un-matched-word{
      background-color: #7aa7c7;
      margin: 2px 5px;
      font-size: 16px;
      padding: 0px 6px;
      border-radius: 6px;

    }
  }
  .time-to-game {
    //width: 20%;
    border-radius: 6px;
    display: flex;
    flex-direction: row-reverse;
    background: aquamarine;
    font-size: 16px;
    text-align: center;
    margin: auto;
  }
}

.game-actions {
  display: flex;
  justify-content: space-around;

  .game-action {
    background-color: #009ff3;
    padding: 6px;
    border-radius: 7px;
    color: white;
    font-size: 16px;
  }
}

.well-done {
  display: flex;
  margin-top: 33px;
  flex-direction: column;
  align-items: center;
  .well-done-icon {
    width: 50px;
    height: 50px;
  }

  input {
    width: 50%;

  }

  .send-score {
    background-color: #009ff3;
    padding: 6px;
    border-radius: 7px;
    color: white;
    font-size: 16px;
    margin: 10px 0px;
    width: 50%;
    text-align: center;
    font-size: 20px;
  }
}

