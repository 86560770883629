.daily-studies-container {

  .daily-studies-title {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
}
