.news-and-update-container {
  margin-top: 20px;

  .news-and-update-title{
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    color: #000000;
  }

  .news-and-update-swipe-container{
    margin: 0px 20px;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    //height: 100% !important;
    //height: 181.85px;
    //border: 0.5px solid rgba(0, 0, 0, 0.3);
    //border-radius: 12px;
    //padding: 10px;

    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 8px 15px 30px 15px;
    flex-direction: column;

    .swipe-item {
      white-space: pre-wrap;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      text-align: center;

      width: 100%;
      height: 100%;
      white-space: pre-wrap;
      word-wrap: break-word;

      color: #000000;

      .swipe-item-link {
        font-size: 14px;
      }

      div,span {
        width: 100%;
        height: 100%;
        white-space: pre-wrap;
        word-wrap: break-word
      }

    }

    .swipe-item.regular-text {
      font-weight: 100 !important;
    }

    .attachment-container {
      display: flex;
      justify-content: center;
      .swipe-item .attachment-item{
        height: 100%;
        width: 100%;
      }

      img {
        max-width:100%;
        max-height:100%;
      }
      iframe {
        max-width:100%;
        max-height:100%;
      }
    }
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .app-icon-increase {
    width: 145px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.attachment-modal-container {
  z-index: 100;
  position: fixed;
  width: 100vw;
  height: 100vw;
  top: 100px;
  padding: 20px;

  .attachment-modal {
    width: 100%;
    height: 100%;
  }


}

.birthday-gif {
  .swipe-item {
    .attachment-item {
      img {
        width: 50vw;
        height: 50vw;
      }
    }
  }

}

.player-container-main-slider {
  height: 100% !important;
  width: 100% !important;
}