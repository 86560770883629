.management-main-container {
  .main-hello {
    font-size: 18px;
  }

  .main-action-buttons {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .zoom-in-out-animation {
      //background-color: yellow;
      animation: zoom-in-zoom-out 1s ease infinite;
    }

    @keyframes zoom-in-zoom-out {
      /* At the beginning of the animation */
      0% {
        /* Scale the element to its original size */
        transform: scale(1, 1);
      }
      /* At the middle of the animation */
      50% {
        /* Scale the element to 1.5 times its original size */
        transform: scale(1.1, 1.1);
      }
      /* At the end of the animation */
      100% {
        /* Scale the element back to its original size */
        transform: scale(1, 1);
      }
    }


    .action-button {
      margin: 10px 10px;
      cursor: pointer;
      padding: 8px 5px;
      width: 85px;
      height: 80px;
      background: #058ED9;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 12px;
        color: #FFFFFF;
        text-align: center;
      }

      .invoice-logo {
        width: 50px;
      }




      .whats-app-button {
        filter: invert(100%) sepia(7%) saturate(0%) hue-rotate(63deg) brightness(104%) contrast(104%);
      }
    }
  }

  .statics {
    background: #C2EAFF;
    border-radius: 12px;
    margin-top: 15px;
    padding: 10px;
    //height: 36vh;


    .statics-title {
      font-weight: 700;
      font-size: 22px;
    }

    .statics-title-divider {
      display: flex;
      justify-content: space-between;
    }

    .statics-select-container {
      text-align: left;
      font-weight: 700;
      font-size: 15px;

      .statics-select {
        width: 130px;

        .ant-select-selector {
          border-radius: 12px;
          .ant-select-selection-item {
            padding-left: 18px;
            padding-right: unset;
          }
        }
        .ant-select-arrow {
          left: 11px;
          right: unset;
        }
      }

      .export-pdf {
        width: 40px;
        height: 40px;
      }

    }

    .statics-body {
      display: flex;
      flex-direction: row;

     .static {
       display: flex;
       flex-direction: column;
       align-items: center;
       width: 50%;
       text-align: center;


       .static-amount {
         margin-top: 20px;
         font-weight: 700;
         font-size: 30px;
       }

       .static-name {
         margin-top: 20px;
         font-weight: 400;
         font-size: 14px;
       }

     }
    }

    .important-date-summery-container {
      border: 0.5px solid #058ED9;
      border-radius: 12px;
      margin-top: 10px;
      padding: 2px;

      .prayer-name {
        font-weight: bolder;
        text-align: center;
      }

      div {
        font-weight: 400;
        font-size: 14px;
      }
    }

    .added-string-info {
      span {
        margin-top: 20px;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

.ant-select-selection-item {
  text-align: start;

}