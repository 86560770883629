.azkara-order-container {

  .name-container {
    text-align: center;
    color: #058ED9;
    font-size: 20px;
    font-weight: 700;
  }

  .azkara-gif {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 50vw;
    }
  }
  padding: 10px;

  .azkara-order-title {
    text-align: center;
    span {
      font-size: 20px;
    }

  }
  .azkara-order-input {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 10px;

    input {
      width: 40%;
      margin: 0px 20px;
      font-size: 14px;
      text-align: center;


    }
  }

  .tfila-container{

    .azkara-buttons-container {
      display: flex;
      justify-content: space-around;

      button {
        background: rgba(109, 200, 250, 0.29);
        border-radius: 12px;
        display: flex;
        margin: unset;
        align-items: center;
      }
    }

    .tfila-helpers {
      font-size: 14px;
      font-weight: 700;
    }

    .tfila-content {
      font-size: 14px;

      .first-digit {
        font-size: 22px;
        font-size: 18px;
        color: #058ED9;;

      }

    }
  }
  .divider-line {
    height: 3px;
    border: none;
    border-radius: 6px;
    background: #C2EAFF;
  }
}