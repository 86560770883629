.jerusalem-compass-container {

  .jerusalem-compass-title {
    font-family: 'Heebo', sans-serif;
    display: flex;
    justify-content: center;

    margin-bottom: 30px;
  }

  .perfect-accuracy {
    font-size: 15px;
    text-align: center;
    margin: 10px;
  }

  .location-metadata-container {
    display: flex;
    flex-direction: column;

    * {
      font-size: 10px;
      color: #C2EAFF;
    }
  }

  .approve-location-messaage {
    background-color: #C2EAFF;
    margin: 20px;
    padding: 6px;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 700;
  }

  .compass {
    position: relative;
    width: 320px;
    height: 320px;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    margin: auto;
  }

  .compass > .arrow {
    position: absolute;
    width: 0;
    height: 0;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 30px 20px 0 20px;
    border-color: red transparent transparent transparent;
    z-index: 1;
  }

  .compass > .compass-circle,
  .compass > .my-point {
    position: absolute;
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.1s ease-out;
    background: url(https://cdn.onlinewebfonts.com/svg/img_467023.png) center
    no-repeat;
    background-size: contain;
  }

  .compass > .my-point {
    opacity: 0;
    width: 20%;
    height: 20%;
    background: rgb(8, 223, 69);
    border-radius: 50%;
    transition: opacity 0.5s ease-out;
  }

  .jerusalem-indicator {
    position: absolute;
    margin: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}